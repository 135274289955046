import { MB, GB, TB } from "./FileEntry";
import { VideoResolutionTier } from "./video";


export const WorkspaceTiers: Record<string, {
  storage_limit: number,
  storage_max_file_size: number,
  max_resolution_tier: VideoResolutionTier,
}> = {
  "free": {
    storage_limit: 1 * GB,
    storage_max_file_size: 500 * MB,
    max_resolution_tier: "480p",
  },
  "indie" : {
    storage_limit: 500 * GB,
    storage_max_file_size: 100 * GB,
    max_resolution_tier: "1440p",
  },
  "pro": {
    storage_limit: 5 * TB,
    storage_max_file_size: 500 * GB,
    max_resolution_tier: "2160p",
  }
}


export type WorkspaceTier = keyof typeof WorkspaceTiers;


export function isWorkspaceTier(str: string): str is WorkspaceTier {
  return Object.keys(WorkspaceTiers).includes(str);
}

export function isHigherTier(a: WorkspaceTier, b: WorkspaceTier) {
  if (a === "free") {
    return false;
  }
  if (a === "indie") {
    return b !== "indie" && b !== "pro";
  }
  if (a === "pro") {
    return b !== "pro";
  }
}
