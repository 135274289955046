"use client";

import { getInstantUser } from "@palette.tools/model.client";
import { Namespaces } from "@palette.tools/model.core";
import type Mux from "@mux/mux-node";




export async function get_instant_token() {

  const response = await fetch("/api/instant-token", {
    method: "GET",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to get instant token", response.status, response.statusText);
    return null;
  }
  const res = await response.json();

  return res['instantToken'] || null;

}


export async function delete_entity(key: keyof Namespaces, id: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return false;

  const response = await fetch(`/api/entity/${key}/${id}`, {
    headers: {
      token: user.refresh_token,
    },
    method: "DELETE",
  });

  if (!response.ok) {
    console.warn("Failed to delete entity", response.status, response.statusText);
    return false;
  }

  return true;
}


export async function can_delete_entity(key: keyof Namespaces, id: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return false;

  const response = await fetch(`/api/entity/${key}/${id}/can-delete`, {
    headers: {
      token: user.refresh_token,
    },
    method: "GET",
    cache: "no-cache",
  });
  return response.ok;
}


export async function count_descendants(key: keyof Namespaces, id: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/entity/${key}/${id}/count-descendants`, {
    headers: {
      token: user.refresh_token,
    },
    method: "GET",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to count descendants", response.status, response.statusText);
    return null;
  }

  return (await response.json()) as Record<keyof Namespaces, number>;
}


export async function refresh_workspace_users(workspaceId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/workspace/${workspaceId}/refresh-users`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to refresh users", response.status, response.statusText);
    return null;
  }

  return true;

}


export async function recalculate_workspace_storage(workspaceId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/workspace/${workspaceId}/recalculate-storage`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to recalculate storage", response.status, response.statusText);
    return null;
  }

  return true;

}


export async function create_invite(workspaceId: string, email: string, projectId?: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/create-invite`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({
      email,
      workspaceId,
      projectId,
    }),
  });

  if (!response.ok) {
    console.warn("Failed to create invite", response.status, response.statusText);
    return null;
  }

  return true;

}


export async function accept_invite(inviteId: string): Promise<{ redirect_url: string } | null> {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/accept-invite/${inviteId}`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to accept invite", response.status, response.statusText);
    return null;
  }

  return await response.json() as { redirect_url: string };

}


export async function cancel_invite(inviteId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/cancel-invite/${inviteId}`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to cancel invite", response.status, response.statusText);
    return null;
  }

  return true;

}


export async function remove_workspace_user(workspaceId: string, userId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/workspace/${workspaceId}/remove-user`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    console.warn("Failed to remove workspace user", response.status, response.statusText);
    return null;
  }

  return true;

}


export async function remove_project_user(projectId: string, userId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/project/${projectId}/remove-user`, {
    headers: {
      token: user.refresh_token,
    },
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    console.warn("Failed to remove project user", response.status, response.statusText);
    return null;
  }

  return true;

}


export async function delete_file_entry(file_id: string, workspace_id: string) {
  await delete_entity("file_entry", file_id);
  await recalculate_workspace_storage(workspace_id);
}


export async function redirect_to_workspace_customer_portal(workspaceId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/workspace/${workspaceId}/redirect-customer-portal`, {
    headers: {
      token: user.refresh_token,
    },
    method: "GET",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to redirect to customer portal", response.status, response.statusText);
    return null;
  }

  return (await response.json()).url as string || null;

}


export async function get_mux_asset(assetId: string) {

  const { user } = await getInstantUser();
  if (!user?.refresh_token) return null;

  const response = await fetch(`/api/mux/${assetId}`, {
    headers: {
      token: user.refresh_token,
    },
    method: "GET",
    cache: "no-cache",
  });

  if (!response.ok) {
    console.warn("Failed to get mux asset", response.status, response.statusText);
    return null;
  }

  return (await response.json()) as Mux.Video.Asset;

}
