

export const DEFAULT_FRAME_RATE = 24.0001;


export type VideoResolutionTier = undefined | "480p" | "540p" | "720p" | "1080p" | "1440p" | "2160p";


export const DEFAULT_VIDEO_RESOLUTION_TIER: VideoResolutionTier = "540p";


export const all_video_resolution_tiers: VideoResolutionTier[] = [
  "480p",
  "540p",
  "720p",
  "1080p",
  "1440p",
  "2160p",
];


export function isHigherResolutionTier(tier1: VideoResolutionTier, tier2: VideoResolutionTier) {

  if (tier1 === tier2) return false;

  const tier1_index = all_video_resolution_tiers.indexOf(tier1);
  const tier2_index = all_video_resolution_tiers.indexOf(tier2);

  return tier1_index > tier2_index;

}


export function isLowerResolutionTier(tier1: VideoResolutionTier, tier2: VideoResolutionTier) {

  if (tier1 === tier2) return false;

  const tier1_index = all_video_resolution_tiers.indexOf(tier1);
  const tier2_index = all_video_resolution_tiers.indexOf(tier2);

  return tier1_index < tier2_index;

}


export type VideoResolutionRange = {
  min?: VideoResolutionTier,
  max?: VideoResolutionTier,
};


export function getAvailableVideoResolutionTiers(range?: VideoResolutionRange, available_tiers = all_video_resolution_tiers): {
  available: VideoResolutionTier[],
  too_low: VideoResolutionTier[],
  too_high: VideoResolutionTier[],
} {

  //console.log("0", {available_tiers, range});

  if (!range || (!range.min && !range.max)) return {available: available_tiers, too_low: [], too_high: []};

  //console.log("1", {range})

  // Fill in the missing min or max if only one is provided
  if (!range.min) {
    range.min = available_tiers[0];
  }
  if (!range.max) {
    range.max = available_tiers[available_tiers.length - 1];
  }

  //console.log("2", {range})

  // Swap min and max if min is greater than max in the all_video_resolution_tiers array
  if (all_video_resolution_tiers.indexOf(range.min) > all_video_resolution_tiers.indexOf(range.max)) {
    range = { min: range.max, max: range.min };
  }

  //console.log("3", {range})

  let min_index = available_tiers.indexOf(range.min);
  let max_index = available_tiers.indexOf(range.max);

  //console.log("4", {min_index, max_index})

  // Handle if either min or max is not found
  if (min_index === -1) {
    min_index = 0;
  }
  if (max_index === -1) {
    max_index = available_tiers.length - 1;
  }

  //console.log("5", {min_index, max_index})

  // Get the available tiers
  const available = available_tiers.slice(min_index, max_index + 1);

  // Get the too low tiers
  const too_low = available_tiers.slice(0, min_index);

  // Get the too high tiers
  const too_high = available_tiers.slice(max_index + 1);

  //console.log("6", {too_low, too_high});

  return { available, too_low, too_high };

}
