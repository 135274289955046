export * from "./events";
export * from "./schema";

import {
    LinkTypes,
    Links,
    ListItemLinkTo,
    ListItemLinkTypes,
    Namespaces, RoleLinkTypes,
    RoleName,
    SchemaEntry,
    SCHEMA_VERSION,
    getListItemToKey,
    getRoleNameFromKey,
    isListItemLinkKey,
    isRoleLinkKey,
    ListItem,
    getFullListItemKey,
    getRoleTargetKey,
    ListItemKey,
    RoleKey,
    getListItemFromKey
} from "./schema";

import {
    InstantObject, id as id_core,
    tx as tx_core
} from "@instantdb/core";
import { getIndicesBetween, sortByIndex } from "@tldraw/indices";

import { MD5 } from "crypto-js";
import { EventWrappedTransactionChunk, InsertEvent, ReorderEvent, TransactionContext, TrashEvent, isEmitVerboseEvents } from "./events";
import { getInsertOperations, removeDuplicates, reorderItems } from "./indices";

import Fuse from "fuse.js";
import { FilterOptions } from "./filter";


export interface InstantDB {
  tx: typeof tx_core,
  id: typeof id_core,
}


// Utilities

export function getHashedId(id: string): string {
  const hashedId = MD5(id).toString();
  return `${hashedId.slice(0, 8)}-${hashedId.slice(8, 12)}-${hashedId.slice(12, 16)}-${hashedId.slice(16, 20)}-${hashedId.slice(20)}`;
}

export function sortByFractionalIndex<T extends { data: { fractional_index: string } }>(items: T[]): T[] {
  return items.sort((a, b) => sortByIndex({ index: a.data.fractional_index }, { index: b.data.fractional_index }));
}

// Create

export type CreationProps<T> =
  {id?: string} // Allow user to specify id
  & T;

type CreateOptions<K extends keyof Namespaces, Namespace = Namespaces[K]> = {
  after?: (key: K, id: string, props: CreationProps<Namespace>) => EventWrappedTransactionChunk[],
  author?: string,
  timestamp?: number,
}

function create<K extends keyof Namespaces, Namespace = Namespaces[K]>(
  db: InstantDB,
  key: K,
  props: CreationProps<Namespace>,
  options?: CreateOptions<K, Namespace>,
): EventWrappedTransactionChunk[] {
  const { id: providedId, ...creationData } = props;

  const id = providedId || db.id();

  return [
    (context) => {
      const transactionData = {
        ...creationData, // Set user props
        trashed: false,
        id,
        schema_version: SCHEMA_VERSION,
        created_by: options?.author || context.author,
        created_at: options?.timestamp || context.timestamp,
        updated_by: options?.author || context.author,
        updated_at: options?.timestamp || context.timestamp,
      }

      return {
        chunk: db.tx[key][transactionData.id].update(transactionData),
        event: {
          type: 'create',
          tx: `tx[${JSON.stringify(key)}][${JSON.stringify(transactionData.id)}].update(${JSON.stringify(transactionData)})`,
          key: key,
          id: transactionData.id,
          data: transactionData,
          timestamp: options?.timestamp || context.timestamp,
          author: options?.author || context.author,
        }
      }
    },
    ...(options?.after ? options?.after?.(key, id, props) : [])
  ]

}

// Update

type UpdateOptions = {
  author?: string,
  timestamp?: number,
}

function update<K extends keyof Namespaces, Namespace = Namespaces[K]>(
  db: InstantDB,
  key: K,
  id: string,
  props: Partial<Namespace>,
  options?: UpdateOptions,
): EventWrappedTransactionChunk[] {

  return [
    (context) => {

      const transactionData = {
        ...props,
        updated_by: options?.author || context.author,
        updated_at: options?.timestamp || context.timestamp,
      };

      return {
        chunk: db.tx[key][id].update(transactionData),
        event: {
          type: 'update',
          tx: `tx[${JSON.stringify(key)}][${JSON.stringify(id)}].update(${JSON.stringify(transactionData)})`,
          key,
          id,
          data: transactionData,
          timestamp: options?.timestamp || context.timestamp,
          author: options?.author || context.author,
        }
      }
    }
  ];

}

// Link

type LinkOptions = {
  author?: string,
  timestamp?: number,
}

function link<K extends keyof Namespaces>(
  db: InstantDB,
  key: K,
  id: string,
  linkedKey: LinkTypes[K],
  linkedId: string,
  options?: LinkOptions,
): EventWrappedTransactionChunk[] {

  const transactionData = { [linkedKey]: linkedId };

  return [(context) => ({
    chunk: db.tx[key][id].link(transactionData),
    event: {
      type: 'link',
      tx: `tx[${JSON.stringify(key)}][${JSON.stringify(id)}].link(${JSON.stringify(transactionData)})`,
      key,
      id,
      data: {
        from: {
          key,
          id,
        },
        to: {
          key: linkedKey,
          id: linkedId,
        },
      },
      timestamp: options?.timestamp || context.timestamp,
      author: options?.author || context.author,
    }
  })]
}

// Unlink

type UnlinkOptions = {
  author?: string,
  timestamp?: number,
}

function unlink<K extends keyof Namespaces>(
  db: InstantDB,
  key: K,
  id: string,
  linkedKey: LinkTypes[K],
  linkedId: string,
  options?: UnlinkOptions,
): EventWrappedTransactionChunk[] {

  const transactionData = { [linkedKey]: linkedId };

  return [(context) => ({
    chunk: db.tx[key][id].unlink(transactionData),
    event: {
      type: 'unlink',
      tx: `tx[${JSON.stringify(key)}][${JSON.stringify(id)}].unlink(${JSON.stringify(transactionData)})`,
      key,
      id,
      data: {
        from: {
          key,
          id,
        },
        to: {
          key: linkedKey,
          id: linkedId,
        },
      },
      timestamp: options?.timestamp || context.timestamp,
      author: options?.author || context.author,
    }
  })]
}

// Reorder items

export type ListTypes<E extends EntityMap, K extends keyof Namespaces> = {
  [
    P in keyof ListItemLinkTypes as P extends LinkTypes[K]
      ? ListItemLinkTo<P>
      : never
  ]: ListItemLinkTo<P> extends keyof Namespaces ? E[ListItemLinkTo<P>][] : never;
}


export type ListItemTypes<E extends EntityMap, K extends keyof Namespaces> = {
  [
    P in keyof ListItemLinkTypes as P extends LinkTypes[K]
      ? ListItemLinkTo<P>
      : never
  ]: P extends keyof E ? E[P] : never;
}


type ListItemOf<E extends EntityMap, K extends keyof Namespaces> = { id: string, data: ListItem } & { links: { [X in K]: E[X][] } };


function getHashedListItemId<E extends EntityMap, K extends keyof Namespaces, P extends keyof ListTypes<E, K>>(
  key: K,
  id: string,
  itemKey: P,
  itemId: string,
): string {
  return getHashedId(`list_item__${key}__${String(itemKey)}__${id}__${itemId}`);
}

type InsertOrReorderOptions<K extends keyof Namespaces, Namespace = Namespaces[K]> = {
  after?: (key: K, id: string, creationParams: CreationProps<Namespace>) => EventWrappedTransactionChunk[],
  author?: string,
  timestamp?: number,
}

function test(key: ListItemKey<"asset", "category">) {

}

test("list_item__asset__in__category")

function _insert_or_reorder<
  E extends EntityMap,
  K extends keyof Namespaces,
  P extends keyof ListTypes<E, K>,
  L extends ListItemOf<E, P>,
>(
  db: InstantDB,
  key: K,
  id: string,
  itemKey: P,
  itemId: string,
  listItems: L[],
  fractional_index: string,
  options?: InsertOrReorderOptions<ListItemKey<P, K>>,
): EventWrappedTransactionChunk[] {

  const listItemKey = getFullListItemKey(key, itemKey);
  const listItemId = getHashedListItemId(key, id, itemKey, itemId);
  const existingListItem = listItems.find(item => item.id === listItemId);

  const txs: EventWrappedTransactionChunk[] = [];


  if (!existingListItem) {
    txs.push(...[

      // Initialize a ListItem to hold the place of the item in the list.
      ...create(db, listItemKey, { id: listItemId, fractional_index }, options),

      // Make the ListItem hold this item.
      (context: TransactionContext) => {
        const event: InsertEvent = {
          type: "insert",
          tx: `tx[${JSON.stringify(listItemKey)}][${JSON.stringify(listItemId)}].link(${JSON.stringify({ [itemKey]: itemId })}))`,
          key: listItemKey,
          id: listItemId,
          data: {
            parent: {
              key,
              id,
            },
            item: {
              key: itemKey as keyof Namespaces,
              id: itemId,
            },
            listItem: {
              key: listItemKey,
              id: listItemId,
            }
          },
          timestamp: options?.timestamp || context.timestamp,
          author: options?.author || context.author,
        };
        return {
          chunk: db.tx[listItemKey][listItemId].link({ [itemKey]: itemId }),
          event: isEmitVerboseEvents ? event : undefined,
        }
      },

      // Insert the ListItem into the parent's list.
      (context: TransactionContext) => {
        const event: InsertEvent = {
          type: "insert",
          tx: `tx[${JSON.stringify(key)}][${JSON.stringify(id)}].link(${JSON.stringify({ [listItemKey]: listItemId })}))`,
          key,
          id,
          data: {
            parent: {
              key,
              id,
            },
            item: {
              key: itemKey as keyof Namespaces,
              id: itemId,
            },
            listItem: {
              key: listItemKey,
              id: listItemId,
            }
          },
          timestamp: options?.timestamp || context.timestamp,
          author: options?.author || context.author,
        }
        return {
          chunk: db.tx[key][id].link({ [listItemKey]: listItemId }),
          event: isEmitVerboseEvents ? event : undefined,
        }
      },

    ]);
  }

  else if (existingListItem.data.fractional_index !== fractional_index) {
    txs.push((context) => {
      const transactionData = {
        fractional_index,
        updated_at: options?.timestamp || context.timestamp,
        updated_by: options?.author || context.author,
      }

      const event: ReorderEvent = {
        type: 'reorder',
        tx: `tx[${JSON.stringify(listItemKey)}][${JSON.stringify(listItemId)}].update(${JSON.stringify(transactionData)})`,
        key: listItemKey,
        id: listItemId,
        data: {
          from: existingListItem.data.fractional_index || null,
          to: fractional_index,
        },
        timestamp: options?.timestamp || context.timestamp,
        author: options?.author || context.author,
      };

      return {
        chunk: db.tx[listItemKey][listItemId].update(transactionData),
        event: isEmitVerboseEvents ? event : undefined,
      }
    });
  }

  /*
  console.log("Calling insert or reorder: ", {
    key,
    id,
    itemKey,
    itemId,
    listItems,
    fractional_index,
    listItemKey,
    listItemId,
    existingListItem,
  })
  */

  return txs;

}


export function reorder_items<
  E extends EntityMap,
  K extends keyof Namespaces,
  P extends keyof ListTypes<E, K>,
  O extends ListTypes<E, K>[P],
  L extends ListItemOf<E, P>,
>(
  db: InstantDB,
  key: K,
  id: string,
  itemKey: P,
  itemIds: string[],
  listItems: L[] | null,
  prev: O | null,
  options?: InsertOrReorderOptions<ListItemKey<P, K>>,
): EventWrappedTransactionChunk[] {

  if (listItems === null || !Array.isArray(prev)) {
    /*
    console.log("Called reorder_items but exiting early: ", {
      key,
      id,
      itemKey,
      itemIds,
      listItems,
      prev,
    } );
    */
    return [];
  }

  const prevArray: any[] = prev;

  //if (prevArray.length === 0) return [];

  const changes: EventWrappedTransactionChunk[] = [];
  const withNew = [
    ...prevArray,
    ...(itemIds.filter(x => !prevArray.some(y => y.id === x)).map(x => ({ id: x }))),
  ];

  const reordered = reorderItems(withNew, itemIds);

  /*
  console.log("called reorder_items()", {
    key,
    id,
    itemKey,
    itemIds,
    listItems,
    prev,
    withNew,
    reordered,
    });
    */

  getInsertOperations(prevArray, reordered).map(({ startItem, endItem, items}) => {
    const startIndex = startItem
      ? listItems.find(listItem => listItem.id === getHashedListItemId(key, id, itemKey, startItem.id))?.data.fractional_index || undefined
      : undefined;
    const endIndex = endItem
      ? listItems.find(listItem => listItem.id === getHashedListItemId(key, id, itemKey, endItem.id))?.data.fractional_index || undefined
      : undefined;
    const indices = getIndicesBetween(startIndex, endIndex, items.length);
    changes.push(...indices.flatMap((fractional_index, i) =>
      _insert_or_reorder(db, key, id, itemKey, items[i].id, listItems, fractional_index, options)
    ));
  });

  return changes;

}


function insert_items<
  E extends EntityMap,
  K extends keyof Namespaces,
  P extends keyof ListTypes<E, K>,
  O extends ListTypes<E, K>[P],
  L extends ListItemOf<E, P>,
>(
  db: InstantDB,
  key: K,
  id: string,
  itemKey: P,
  itemIds: string[],
  listItems: L[] | null,
  prev: O | null,
  index?: number,
  options?: InsertOrReorderOptions<ListItemKey<P, K>>,
): EventWrappedTransactionChunk[] {

  if (listItems === null || !Array.isArray(prev)) {
    /*
    console.log("Called insert_items but exiting early: ", {
      key,
      id,
      itemKey,
      itemIds,
      listItems,
      prev,
    } );
    */
    return []
  };

  const prevArray: any[] = prev;

  const changes: EventWrappedTransactionChunk[] = [];
  itemIds = removeDuplicates(itemIds);

  const startItem = index !== undefined && index > 0 && index <= prevArray.length ? prevArray[index - 1] : null;
  const endItem = index !== undefined && index < prevArray.length ? prevArray[index] : null;
  const startIndex = startItem
    ? listItems.find(listItem => listItem.id === getHashedListItemId(key, id, itemKey, startItem.id))?.data.fractional_index || undefined
    : undefined;
  const endIndex = endItem
    ? listItems.find(listItem => listItem.id === getHashedListItemId(key, id, itemKey, endItem.id))?.data.fractional_index || undefined
    : undefined;

  const indices = getIndicesBetween(startIndex, endIndex, itemIds.length);
  changes.push(...indices.flatMap((fractional_index, i) =>
    _insert_or_reorder(db, key, id, itemKey, itemIds[i], listItems, fractional_index, options)
  ));

  return changes;

}


type RemoveItemOptions = {
  author?: string,
  timestamp?: number,
}

function remove_items<
  E extends EntityMap,
  K extends keyof Namespaces,
  P extends keyof ListTypes<E, K>,
  L extends ListItemOf<E, P>,
>(
  db: InstantDB,
  key: K,
  itemKey: P,
  itemIds: string[],
  listItems: L[] | null,
  options?: RemoveItemOptions,
): EventWrappedTransactionChunk[] {

  if (!Array.isArray(listItems)) return [];
  const listItemsArray: any[] = listItems;
  if (listItemsArray.length < 1) { return [] };

  const changes: EventWrappedTransactionChunk[] = [];
  const listItemKey = getFullListItemKey(key, itemKey);

  const listItemsToRemove = listItemsArray.filter(listItem => {
    if (!Array.isArray(listItem.links[itemKey])) { return false }
    const linksArray: any[] = listItem.links[itemKey];
    return linksArray.some(x => itemIds.includes(x['id']));
  });

  listItemsToRemove.map(listItem => (context: TransactionContext) => {
    const transactionData = { trashed: true, trashed_by: options?.author || context.author, trashed_at: options?.timestamp || context.timestamp };
    const trashEvent: TrashEvent = {
      type: "trash",
      tx: `tx[${JSON.stringify(listItemKey)}][${JSON.stringify(listItem.id)}].update(${JSON.stringify(transactionData)})`,
      key: listItemKey,
      id: listItem.id,
      data: transactionData,
      timestamp: options?.timestamp || context.timestamp,
      author: options?.author || context.author,
    }
    changes.push(...[
      {
        chunk: db.tx[listItemKey][listItem.id].update(transactionData),
        event: isEmitVerboseEvents ? trashEvent : undefined,
      }
    ]);
  });

  return changes;
}


// Assign items

type RoleTypes<K extends keyof Namespaces> = {
  [P in keyof RoleLinkTypes as P extends `role__${K}__${string}` ? RoleName<P> : never]: string[];
};

type AssignRoleOptions<K extends keyof Namespaces, Namespace = Namespaces[K]> = {
  after?: (key: K, id: string, creationParams: CreationProps<Namespace>) => EventWrappedTransactionChunk[],
  author?: string,
  timestamp?: number,
}

function assign_role<K extends keyof Namespaces, R extends keyof RoleTypes<K>>(
  db: InstantDB,
  key: K,
  id: string,
  roleKey: R,
  profileId: string,
  options?: AssignRoleOptions<RoleKey<K, R>>,
): EventWrappedTransactionChunk[] {

  const fullRoleKey = `role__${key}__${String(roleKey)}` as RoleKey<K, R>;
  const roleId = getHashedId(`${id}__role__${String(roleKey)}__${profileId}`);

  return [

    ...create(db, fullRoleKey, { id: roleId }, options),

    (context) => ({
      chunk: db.tx[key][id].link({ [fullRoleKey]: roleId }),
      event: {
        type: 'assign',
        tx: `tx[${JSON.stringify(key)}][${JSON.stringify(id)}].link(${JSON.stringify({ [fullRoleKey]: roleId })})`,
        key,
        id,
        data: {
          target: {
            key,
            id,
          },
          role: roleKey,
          assignee: profileId,
          assigner: options?.author || context.author,
        },
        timestamp: options?.timestamp || context.timestamp,
        author: options?.author || context.author,
      },
    }),

    (context) => ({
      chunk: db.tx['profile'][profileId].link({ [fullRoleKey]: roleId }),
      event: {
        type: 'assign',
        tx: `tx['profile'][${JSON.stringify(profileId)}].link(${JSON.stringify({ [fullRoleKey]: roleId })})`,
        key: 'profile',
        id: profileId,
        data: {
          target: {
            key,
            id,
          },
          role: roleKey,
          assignee: profileId,
          assigner: options?.author || context.author,
        },
        timestamp: options?.timestamp || context.timestamp,
        author: options?.author || context.author,
      },
    }),
  ]

}

type UnassignRoleOptions = {
  author?: string,
  timestamp?: number,
}

function unassign_role<K extends keyof Namespaces>(
  db: InstantDB,
  key: K,
  id: string,
  roleKey: keyof RoleTypes<K>,
  profileId: string,
  options?: UnassignRoleOptions,
): EventWrappedTransactionChunk[] {

  const fullRoleKey = `role__${key}__${String(roleKey)}` as keyof RoleLinkTypes;
  const roleId = getHashedId(`${id}__role__${String(roleKey)}__${profileId}`);

  return [(context) => {
    const transactionData = {
      trashed: true,
      trashed_by: options?.author || context.author,
      trashed_at: options?.timestamp || context.timestamp,
    };
    return {
      chunk: db.tx[fullRoleKey][roleId].update(transactionData),
      event: {
        type: 'unassign',
        tx: `tx[${JSON.stringify(fullRoleKey)}][${JSON.stringify(roleId)}].update(${JSON.stringify(transactionData)})`,
        key: fullRoleKey,
        id: roleId,
        data: {
          target: {
            key,
            id,
          },
          role: roleKey,
          assignee: profileId,
          assigner: options?.author || context.author,
        },
        timestamp: options?.timestamp || context.timestamp,
        author: options?.author || context.author,
      }
    }
  }];
}

function is_role<K extends keyof Namespaces, R extends keyof RoleTypes<K>>(
  roleKey: R,
  roles: RoleTypes<K>,
  profile: string,
): boolean {
  return Array.isArray(roles[roleKey]) && (roles[roleKey] as string[]).includes(profile);
}


// Transform InstantObject into entities.

export function _transform_data<E extends EntityMap, K extends keyof Namespaces, EntityClass>(
  db: InstantDB,
  key: K,
  data: InstantObject,
  cache: Map<string, any> = new Map(),
  transforming: Set<string> = new Set()
): EntityClass {
  const entityId = data.id;

  const dataWithoutLinks = Links[key].reduce((acc, linkedKey) => {
    delete acc[linkedKey];
    return acc;
  }, {...data}) as SchemaEntry<K>;

  // Check if the entity is already being transformed
  if (transforming.has(entityId)) {
    // If the entity is currently being transformed, return the cached entity
    const cachedEntity = cache.get(entityId);
    //Object.assign(cachedEntity, new Entity(db, key, cachedEntity.data, cachedEntity.links));
    return cachedEntity;
  }

  // Check if the entity has already been transformed and cached
  const cachedEntity = cache.get(entityId);
  if (cachedEntity) {
    // Merge the links from the new data into the cached entity

    const mergedLinks: LinkedEntities<E, K> = {...cachedEntity.links};

    Links[key].forEach(linkedKey => {
      const linkEntries = data[linkedKey] as LinkedEntities<E, K>[typeof linkedKey] | null;
      if (!linkEntries) return;

      if (!mergedLinks[linkedKey]) {
        mergedLinks[linkedKey] = [];
      }

      linkEntries.forEach(linkEntry => {
        const linkEntityId = linkEntry.id;
        const cachedLinkEntity = cache.get(linkEntityId);
        if (!mergedLinks[linkedKey]?.some(x => x.id === linkEntityId)) {
          if (!!cachedLinkEntity)  {
            Object.assign(cachedLinkEntity, new Entity(db, linkedKey, cachedLinkEntity.data, cachedLinkEntity.links));
            mergedLinks[linkedKey]?.push(cachedLinkEntity);
          }
          else {
            mergedLinks[linkedKey]?.push(transform_data(
              db,
              linkedKey,
              linkEntry,
              cache,
              transforming
            ));
          }
        }
      });
    });

    if (key === "category") {
      //console.log("merged links", key, mergedLinks);
    }

    cache.set(entityId, cachedEntity);

    /*
    [...cache.keys()].forEach((key) => {
      const other = cache.get(key);
      Object.assign(other, new Entity(db, other.key, other.data, other.links));
    });
    */

    Object.assign(cachedEntity, new Entity(db, key, dataWithoutLinks, mergedLinks));

    return cachedEntity;
  }

  // Create a new entity object
  const entity = new Entity(db, key, dataWithoutLinks);

  // Cache the new entity
  cache.set(entityId, entity);

  // Add the entity ID to the transforming set
  transforming.add(entityId);

  const links: LinkedEntities<E, K> = {...entity.links};

  // Recursively traverse links and assign them to the entity
  Links[key].forEach((linkedKey) => {
    const linkEntries = data[linkedKey];
    if (Array.isArray(linkEntries)) {
      links[linkedKey] = linkEntries.map((linkEntry: any) =>
        _transform_data<E, typeof linkedKey, E[typeof linkedKey]>(
          db,
          linkedKey,
          linkEntry,
          cache,
          transforming
        )
      );
    } else {
      links[linkedKey] = null;
    }
  });

  Object.assign(entity, new Entity(db, key, dataWithoutLinks, links));

  // Remove the entity ID from the transforming set
  transforming.delete(entityId);

  return entity as EntityClass;
}


export function transform_data<E extends EntityMap, K extends keyof Namespaces, EntityClass>(
  db: InstantDB,
  key: K,
  data: InstantObject,
  cache: Map<string, any> = new Map(),
  transforming: Set<string> = new Set()
): EntityClass {
  const entity = _transform_data<E, K, EntityClass>(db, key, data, cache, transforming);
  Array.from(cache.keys()).forEach((key) => {
    const other = cache.get(key);
    Object.assign(other, new Entity(db, other.key, other.data, {...other.links}));
  });
  return entity;
}


export function serialize<E extends Entity<any, any, any>>(
  entity: E,
  path: string[] = [],
  serialized: Map<string, InstantObject> = new Map()
): InstantObject {
  const entityId = entity.id;

  // Check if the entity has already been serialized within the current path
  if (path.includes(entityId)) {
    // If the entity is already in the path, return a placeholder object with just the id
    return { id: entityId };
  }

  // Check if the entity has already been serialized outside the current path
  if (serialized.has(entityId)) {
    // If the entity has been serialized before, return the serialized object
    return serialized.get(entityId)!;
  }

  // Add the entity ID to the current path
  path.push(entityId);

  const serializedEntity: InstantObject = {
    id: entityId,
    ...entity.data,
    ...Object.fromEntries(
      Object.entries(entity.links)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => [
          key,
          (value || []).map((link: E) => serialize(link, [...path], serialized)),
        ])
    ),
  };

  // Add the serialized entity to the serialized map
  serialized.set(entityId, serializedEntity);

  // Remove the entity ID from the current path
  path.pop();

  return serializedEntity;
}


export function deserialize<K extends keyof Namespaces, EntityClass>(
  db: InstantDB,
  key: K,
  data: InstantObject,
): EntityClass {
  if (!data['id']) {
    throw new Error("Cannot deserialize entity without id");
  }
  return transform_data(db, key, data as InstantObject);
}


export function filter_entities<E extends EntityMap, K extends keyof Namespaces>(
  key: K,
  entities: E[K][],
  term: string,
  options?: Fuse.IFuseOptions<any>
): E[K][] {
  options = options || FilterOptions[key];
  if (!term) {
    return entities;
  }
  const fuse = new Fuse(entities, options);
  return fuse.search(term).map((filteredDocument) => filteredDocument.item) as E[K][];
}


export class Entity<E extends EntityMap, K extends keyof Namespaces, T extends SchemaEntry<K>, Namespace = Namespaces[K]>{
  public readonly db: InstantDB;
  public readonly key: K;
  public readonly id: string;
  public readonly data: T;
  public readonly links: LinkedEntities<E, K>;
  public readonly lists: ListTypes<E, K>;
  public readonly roles: RoleTypes<K>;

  constructor(db: InstantDB, key: K, data: T, links?: LinkedEntities<E, K>) {
    this.db = db;
    this.key = key;
    this.id = data.id;
    this.data = data;

    // Initialize links.
    this.links = { ...Links[key].reduce(
      (acc, linkedKey) => { return {...acc, [linkedKey]: null}
    }, {} as LinkedEntities<E, K>), ...links };

    // Sort lists.
    this.lists = Object.keys(this.links).reduce(
      (acc, listItemKey) => {
        if (isListItemLinkKey(listItemKey)) {

          if (getListItemFromKey(listItemKey) !== this.key) {
            return acc;
          }

          const toKey = getListItemToKey(listItemKey) as keyof typeof this.links;

          if (!Array.isArray(this.links[toKey])) {
            return {...acc, [toKey]: null};
          }

          const sortedListItems: {
            data: { fractional_index: string },
            links: { [key: string]: { id: string }[] },
          }[] = sortByFractionalIndex(this.links[listItemKey as keyof typeof this.links] || []);


          const filteredList = (this.links[toKey] || []).filter((item) => sortedListItems.some(x => x.links[toKey]?.some(y => y.id === item.id)));

          const sortedList = filteredList.sort((a, b) => {
            const aIndex = sortedListItems.findIndex((listItem) => Array.isArray(listItem.links[toKey]) && listItem.links[toKey].some(x => x.id == a.id));
            const bIndex = sortedListItems.findIndex((listItem) => Array.isArray(listItem.links[toKey]) && listItem.links[toKey].some(x => x.id == b.id));
            return aIndex - bIndex;
          });

          if (key === "category" && listItemKey.includes("asset") && !listItemKey.includes("group")) {
            //console.log("links", this.links);
            //console.log(`this.links[${toKey}]`, this.links[toKey]);
            //console.log(`this.links[${toKey}].map(...)`, (this.links[toKey] || []).map((item) => sortedListItems.some(x => x.links[toKey]?.some(y => y.id === item.id))));
            //console.log("filteredList", filteredList);
            //console.log("sortedList", sortedList);
            //console.log("list of " + listItemKey, this.lists);
            //console.log("sortedListItems", JSON.parse(JSON.stringify(sortedListItems)));
          }

          return {...acc, [toKey]: sortedList};
        }
        return acc;
      }, {} as ListTypes<E, K>);


      // Initialize roles.
      this.roles = Object.keys(this.links).reduce(
        (acc, linkedKey) => {
          if (isRoleLinkKey(linkedKey) && getRoleTargetKey(linkedKey) === key) {
            const roleData = this.links[linkedKey as keyof typeof this.links];
            const roleKey = linkedKey as keyof RoleTypes<K>;
            const roleName = getRoleNameFromKey(roleKey as keyof RoleLinkTypes);
            if (!Array.isArray(roleData)) {
              return {...acc, [roleName]: null};
            }
            return {...acc, [roleName]: roleData.flatMap((role) => role.links.profile || []).map((profile) => profile.id)};
          }
          return acc;
      }, {} as RoleTypes<K>);

  }

  public serialize(): InstantObject { return serialize(this) }
  public filter(entities: E[K][], term: string, options?: Fuse.IFuseOptions<any>) { return filter_entities(this.key, entities, term, options) }
  public is_role(roleKey: keyof RoleTypes<K>, profile: string) { return is_role(roleKey, this.roles, profile) }

  // Transactions
  public create(props: CreationProps<Namespace>, options?: CreateOptions<K, Namespace>) { return create(this.db, this.key, props, options) }
  public update(props: Partial<Namespace>, options?: UpdateOptions) { return update(this.db, this.key, this.id, props, options) }
  public link(linkedKey: LinkTypes[K], linkedId: string, options?: LinkOptions) { return link(this.db, this.key, this.id, linkedKey, linkedId, options) }
  public unlink(linkedKey: LinkTypes[K], linkedId: string, options?: UnlinkOptions) { return unlink(this.db, this.key, this.id, linkedKey, linkedId, options) }
  public assign_role<R extends keyof RoleTypes<K>>(roleKey: R, profile: string, options?: AssignRoleOptions<RoleKey<K, R>>) { return assign_role(this.db, this.key, this.id, roleKey, profile, options) }
  public unassign_role(roleKey: keyof RoleTypes<K>, profile: string, options?: UnassignRoleOptions) { return unassign_role(this.db, this.key, this.id, roleKey, profile, options) }
  public reorder_items<P extends keyof ListTypes<E, K>>(itemKey: P, itemIds: string[], options?: InsertOrReorderOptions<ListItemKey<P, K>>) { return reorder_items(this.db, this.key, this.id, itemKey, itemIds, this.links[getFullListItemKey(this.key, itemKey) as unknown as keyof LinkedEntities<E, K>], this.lists[itemKey], options) }
  public insert_items<P extends keyof ListTypes<E, K>>(itemKey: P, itemIds: string[], index?: number, options?: InsertOrReorderOptions<ListItemKey<P, K>>) { return insert_items(this.db, this.key, this.id, itemKey, itemIds, this.links[getFullListItemKey(this.key, itemKey) as unknown as keyof LinkedEntities<E, K>], this.lists[itemKey], index, options) }
  public remove_items<P extends keyof ListTypes<E, K>>(itemKey: P, itemIds: string[], options?: RemoveItemOptions) { return remove_items(this.db, this.key, itemKey, itemIds, this.links[getFullListItemKey(this.key, itemKey) as unknown as keyof LinkedEntities<E, K>], options) }

}


export function createEntityClass<E extends EntityMap, K extends keyof Namespaces, T extends SchemaEntry<K>, Namespace = Namespaces[K]>(
  db: InstantDB,
  key: K,
) {

  class NewClass extends Entity<E, K, T> {
    public static key = key;

    public static serialize<DerivedClass extends NewClass>(this: EntityStatic<E, K, T, DerivedClass>, entity: DerivedClass): InstantObject { return serialize(entity) }
    public static deserialize<DerivedClass extends NewClass>(this: EntityStatic<E, K, T, DerivedClass>, serialized: InstantObject): DerivedClass { return deserialize(db, key, serialized) }
    public static filter<DerivedClass extends NewClass>(this: EntityStatic<E, K, T, DerivedClass>, entities: E[K][], term: string, options?: Fuse.IFuseOptions<any>) { return filter_entities(key, entities, term, options) }
    //public static is_role(roleKey: keyof RoleTypes<K>, roles: RoleTypes<K>, profile: string) { return is_role(roleKey, roles, profile) }
    public static is_role = is_role;

    // Transactions
    public static create(props: CreationProps<Namespace>, options?: CreateOptions<K, Namespace>) { return create(db, key, props, options) }
    public static update(id: string, props: Partial<Namespace>, options?: UpdateOptions) { return update(db, key, id, props, options) }
    public static link<L extends LinkTypes[K]>(id: string, linkedKey: L, linkedId: string, options?: LinkOptions) { return link(db, key, id, linkedKey, linkedId, options) }
    public static unlink(id: string, linkedKey: LinkTypes[K], linkedId: string, options?: UnlinkOptions) { return unlink(db, key, id, linkedKey, linkedId, options) }
    public static assign_role<R extends keyof RoleTypes<K>>(id: string, roleKey: R, profile: string, options?: AssignRoleOptions<RoleKey<K, R>>) { return assign_role(db, key, id, roleKey, profile, options) }
    public static unassign_role(id: string, roleKey: keyof RoleTypes<K>, profile: string, options?: UnassignRoleOptions) { return unassign_role(db, key, id, roleKey, profile, options) }
    public static reorder_items<P extends keyof ListTypes<E, K>, O extends ListTypes<E, K>[P], L extends ListItemOf<E, P>>(id: string, itemKey: P, itemIds: string[], listItems: L[], prev: O | null, options?: InsertOrReorderOptions<ListItemKey<P, K>>) { return reorder_items(db, key, id, itemKey, itemIds, listItems, prev, options) };
    public static insert_items<P extends keyof ListTypes<E, K>, O extends ListTypes<E, K>[P], L extends ListItemOf<E, P>>(id: string, itemKey: P, itemIds: string[], listItems: L[], prev: O | null, index?: number, options?: InsertOrReorderOptions<ListItemKey<P, K>>) { return insert_items(db, key, id, itemKey, itemIds, listItems, prev, index, options) }
    public static remove_items<P extends keyof ListTypes<E, K>, L extends ListItemOf<E, P>>(itemKey: P, itemIds: string[], listItems: L[], options?: RemoveItemOptions) { return remove_items(db, key, itemKey, itemIds, listItems, options) }

  }
  return NewClass;
}

export interface EntityStatic<E extends EntityMap, K extends keyof Namespaces, T extends SchemaEntry<K>, DerivedClass extends Entity<E, K, T>> {
  new (db: InstantDB, key: K, data: T): DerivedClass;
  serialize(entity: DerivedClass): ReturnType<typeof serialize>;
  deserialize(serialized: InstantObject): DerivedClass;
  filter(entities: E[K][], term: string, options?: Fuse.IFuseOptions<any>): ReturnType<typeof filter_entities>;
  is_role(roleKey: keyof RoleTypes<K>, roles: RoleTypes<K>, profile: string): ReturnType<typeof is_role>;

  // Transactions
  create(props: CreationProps<Namespaces[K]>, options?: CreateOptions<K>): ReturnType<typeof create>;
  update(id: string, props: Partial<Namespaces[K]>, options?: UpdateOptions): ReturnType<typeof update>;
  link<L extends LinkTypes[K]>(id: string, linkedKey: L, linkedId: string, options?: LinkOptions): ReturnType<typeof link>;
  unlink(id: string, linkedKey: LinkTypes[K], linkedId: string, options?: UnlinkOptions): ReturnType<typeof unlink>;
  assign_role<R extends keyof RoleTypes<K>>(id: string, roleKey: keyof RoleTypes<K>, profile: string, options?: AssignRoleOptions<RoleKey<K, R>>): ReturnType<typeof assign_role>;
  unassign_role(id: string, roleKey: keyof RoleTypes<K>, profile: string, options?: UnassignRoleOptions): ReturnType<typeof unassign_role>;
  reorder_items<P extends keyof ListTypes<E, K>, O extends ListTypes<E, K>[P], L extends ListItemOf<E, P>>(id: string, itemKey: P, itemIds: string[], listItems: L[], prev: O | null, options?: InsertOrReorderOptions<ListItemKey<P, K>>): ReturnType<typeof reorder_items>;
  insert_items<P extends keyof ListTypes<E, K>, O extends ListTypes<E, K>[P], L extends ListItemOf<E, P>>(id: string, itemKey: P, itemIds: string[], listItems: L[], prev: O | null, index?: number, options?: InsertOrReorderOptions<ListItemKey<P, K>>): ReturnType<typeof insert_items>;
  remove_items<P extends keyof ListTypes<E, K>, L extends ListItemOf<E, P>>(itemKey: P, itemIds: string[], listItems: L[], options?: RemoveItemOptions): ReturnType<typeof remove_items>;

}


export type LinkedEntities<E extends EntityMap, K extends keyof Namespaces> = {
  [L in LinkTypes[K]]: E[L][] | null;
};

export interface EntityMap extends Record<keyof Namespaces, any> {}
