export const SCHEMA_VERSION = "0.0.0";

export interface ListItem {
  fractional_index: string;
}

export interface Namespaces {

  profile: {
    clerkId: string;
    email: string;
    first: string;
    last: string;
    last_active?: number
    image_url?: string;
    usage?: {
      page__category?: {
        last_used?: number;
      },
      page__my_tasks?: {
        last_used?: number;
      },
      page__review?: {
        last_used?: number;
      },
    }
  },
  ticket__invite: {
    email: string;
  },

  // Core entities
  workspace: {
    discord_guild_id?: string;
    name: string;
    storage_count?: number;
    storage_size?: number;
    storage_limit?: number;
    storage_max_file_size?: number;
    stripe_cancel_at_period_end?: boolean;
    stripe_customer_id?: string;
    stripe_period_end?: number;
    stripe_status?: string;
    thumbnail_crop?: string;
    thumbnail_uncropped_url?: string;
    thumbnail_url?: string;
    tier?: string;
  },

  project: {
    name: string;
    thumbnail_crop?: string;
    thumbnail_uncropped_url?: string;
    thumbnail_url?: string;
  },

  category: {
    discord_channel_ids?: string[];
    emoji: string;
    name: string;
    default_tab?: "list" | "thread";
  },
  asset: {
    name: string;
    description?: string;
    thumbnail_crop?: string;
    thumbnail_uncropped_url?: string;
    thumbnail_url?: string;
  },
  task: {
    name: string;
    shortcut_url?: string;
    status: number;
    deadline?: number;
    estimate?: number;
  },
  phase: {
    name: string;
    deadline: number;
    estimate: number;
  }

  // Groups
  group__category: {
    name: string;
  },
  group__asset: {
    name: string;
  },

  // Roles
  role__workspace__admin: {},
  role__project__admin: {},
  role__project__editor: {},
  role__task__assignee: {},

  // List items
  list_item__category__in__project: ListItem,
  list_item__group__category__in__project: ListItem,
  list_item__category__in__group__category: ListItem,
  list_item__asset__in__category: ListItem,
  list_item__group__asset__in__category: ListItem,
  list_item__asset__in__group__asset: ListItem,
  list_item__task__in__asset: ListItem,

  // Payloads
  file_entry: {
    active: boolean;
    mime_type: string;
    mux_asset_id?: string;
    mux_asset_ready?: boolean;
    name: string;
    path: string;
    size: number;
    status: number;
    thumbnail_url?: string;
    url?: string;
    uploaded_at?: number;
  },
  comment: {
    frame: number | null,
    message: string,
    edited: boolean,
    attachments?: { key: "file_entry", id: string }[],
  },
  drawover: {
    active: boolean;
    frame: number;
    mime_type: string;
    path: string;
    size: number;
    status: number;
    is_empty?: boolean;
    url?: string;
    uploaded_at?: number;
  },

  // Attachments
  attachment__file_entry: {},

  // Tickets
  ticket__discord_upload_attachment: {
    attachment_id: string;
    attachment_name: string;
    attachment_type: string;
    attachment_url: string;
    author_id: string;
    author_username: string;
    channel_id: string;
    guild_id: string;
    invoker_id: string;
    invoker_username: string;
    message_created_at: number;
    status: number;
  }

}

export interface Metadata {
  id: string;
  trashed: boolean;
  schema_version: string;
  created_at?: number;
  created_by?: string;
  updated_at?: number;
  updated_by?: string;
  trashed_at?: number;
  trashed_by?: string;
}

const metadata: Metadata = {
  id: "",
  trashed: false,
  schema_version: SCHEMA_VERSION,
  created_at: 0,
  created_by: "string",
  updated_at: 0,
  updated_by: "string",
  trashed_at: 0,
  trashed_by: "string",
}

type OptionalProps<T> = {
  [P in keyof T]?: T[P];
};

export type SchemaEntry<K extends keyof Namespaces> = OptionalProps<Namespaces[K]> & Metadata;

export type Schema = {
  [K in keyof Namespaces]: SchemaEntry<K>
};

export const schema: Schema = {

  profile: {
    ...metadata,
    clerkId: "",
    email: "",
    first: "",
    last: "",
    image_url: "",
  },
  ticket__invite: {
    ...metadata,
    email: "",
  },

  // Core entities
  workspace: {
    ...metadata,
    name: "",
    storage_count: 0,
    storage_size: 0,
    storage_limit: 0,
    storage_max_file_size: 0,
    stripe_cancel_at_period_end: false,
    stripe_customer_id: "",
    stripe_period_end: 0,
    stripe_status: "",
    thumbnail_crop: "{}",
    thumbnail_uncropped_url: "",
    thumbnail_url: "",
    tier: "",
  },
  project: {
    ...metadata,
    name: "",
    thumbnail_crop: "{}",
    thumbnail_uncropped_url: "",
    thumbnail_url: "",
  },
  category: {
    ...metadata,
    emoji: "",
    name: "",
  },
  asset: {
    ...metadata,
    name: "",
    description: "",
    thumbnail_crop: "{}",
    thumbnail_uncropped_url: "",
    thumbnail_url: "",
  },
  task: {
    ...metadata,
    name: "",
    shortcut_url: "",
    status: 0,
    deadline: 0,
    estimate: 0,
  },
  phase: {
    ...metadata,
    name: "",
    deadline: 0,
    estimate: 0,
  },

  // Groups
  group__category: {
    ...metadata,
    name: "",
  },
  group__asset: {
    ...metadata,
    name: "",
  },

  // Roles
  role__workspace__admin: {
    ...metadata,
  },
  role__project__admin: {
    ...metadata,
  },
  role__project__editor: {
    ...metadata,
  },
  role__task__assignee: {
    ...metadata,
  },

  // List items
  list_item__category__in__project: {
    ...metadata,
    fractional_index: "",
  },
  list_item__group__category__in__project: {
    ...metadata,
    fractional_index: "",
  },
  list_item__category__in__group__category: {
    ...metadata,
    fractional_index: "",
  },
  list_item__asset__in__category: {
    ...metadata,
    fractional_index: "",
  },
  list_item__group__asset__in__category: {
    ...metadata,
    fractional_index: "",
  },
  list_item__asset__in__group__asset: {
    ...metadata,
    fractional_index: "",
  },
  list_item__task__in__asset: {
    ...metadata,
    fractional_index: "",
  },

  // Comment threads
  file_entry: {
    ...metadata,
    name: "",
    size: 0,
    url: "",
  },
  comment: {
    ...metadata,
    frame: 0,
    message: "",
  },
  drawover: {
    ...metadata,
    frame: 0,
    url: "",
  },

  // Attachments
  attachment__file_entry: {
    ...metadata,
  },

  // Tickets
  ticket__discord_upload_attachment: {
    ...metadata,
    attachment_url: "",
    attachment_id: "",
    author_id: "",
    channel_id: "",
    guild_id: "",
    status: 0,
  }

};

export function getFieldNames<K extends keyof Namespaces>(key: K): (keyof Schema[K])[] {
  return Object.keys(schema[key]) as (keyof Schema[K])[]
}

export const Links: { [K in keyof Namespaces]: LinkTypes[K][] } = {

  profile: ["workspace", "project", "phase", "role__workspace__admin", "role__project__admin", "role__project__editor", "role__task__assignee"],
  ticket__invite: ["workspace", "project"],

  workspace: [
    "profile",
    "ticket__invite",
    "project",
    "category",
    "asset",
    "task",
    "phase",
    "group__category",
    "group__asset",
    "role__workspace__admin",
    "role__project__admin",
    "role__project__editor",
    "role__task__assignee",
    "list_item__category__in__project",
    "list_item__group__category__in__project",
    "list_item__category__in__group__category",
    "list_item__asset__in__category",
    "list_item__group__asset__in__category",
    "list_item__asset__in__group__asset",
    "list_item__task__in__asset",
    "file_entry",
    "comment",
    "drawover",
    "attachment__file_entry",
  ],
  project: [
    "profile",
    "ticket__invite",
    "workspace",
    "category",
    "asset",
    "task",
    "phase",
    "group__category",
    "group__asset",
    "role__project__admin",
    "role__project__editor",
    "role__task__assignee",
    "list_item__category__in__project",
    "list_item__group__category__in__project",
    "list_item__category__in__group__category",
    "list_item__asset__in__category",
    "list_item__group__asset__in__category",
    "list_item__asset__in__group__asset",
    "list_item__task__in__asset",
    "file_entry",
    "comment",
    "drawover",
    "attachment__file_entry",
  ],
  category: ["workspace", "project", "asset", "group__category", "group__asset", "list_item__category__in__project", "list_item__group__category__in__project", "list_item__asset__in__category", "list_item__group__asset__in__category", "file_entry", "comment"],
  asset: ["workspace", "project", "category", "task", "group__asset", "list_item__asset__in__category", "list_item__asset__in__group__asset", "list_item__task__in__asset", "file_entry", "comment"],
  task: ["workspace", "project", "asset", "phase", "role__task__assignee", "list_item__task__in__asset", "file_entry", "comment"],
  phase: ["workspace", "project", "task", "profile"],

  group__category: ["workspace", "project", "category", "list_item__group__category__in__project", "list_item__category__in__group__category"],
  group__asset: ["workspace", "project", "category", "asset", "list_item__group__asset__in__category", "list_item__asset__in__group__asset"],

  // Workspace link has to go at end, [0] and [1] are important, order-wise
  role__workspace__admin: ["workspace", "profile", "project"],
  role__project__admin: ["project", "profile", "workspace"],
  role__project__editor: ["project", "profile", "workspace"],
  role__task__assignee: ["task", "profile", "workspace", "project"],

  // Workspace link has to go at end, [0] and [1] are important, order-wise
  list_item__category__in__project: ["category", "project", "workspace"],
  list_item__group__category__in__project: ["group__category", "project", "workspace"],
  list_item__category__in__group__category: ["category", "group__category", "workspace", "project"],
  list_item__asset__in__category: ["asset", "category", "workspace", "project"],
  list_item__group__asset__in__category: ["group__asset", "category", "workspace", "project"],
  list_item__asset__in__group__asset: ["asset", "group__asset", "workspace", "project"],
  list_item__task__in__asset: ["task", "asset", "workspace", "project"],

  file_entry: ["workspace", "project", "category", "asset", "task", "comment", "drawover", "attachment__file_entry", "ticket__discord_upload_attachment"],
  comment: ["workspace", "project", "category", "asset", "task", "file_entry", "attachment__file_entry"],
  drawover: ["workspace", "project", "file_entry"],

  attachment__file_entry: ["workspace", "project", "comment", "file_entry"],

  ticket__discord_upload_attachment: ["file_entry"],

}

export type LinkTypes = {

  profile: "workspace" | "project" | "phase" | "role__workspace__admin" | "role__project__admin" | "role__project__editor" | "role__task__assignee";
  ticket__invite: "workspace" | "project";

  workspace: (
    "profile"
    | "ticket__invite"
    | "project"
    | "category"
    | "asset"
    | "task"
    | "phase"
    | "group__category"
    | "group__asset"
    | "role__workspace__admin"
    | "role__project__admin"
    | "role__project__editor"
    | "role__task__assignee"
    | "list_item__category__in__project"
    | "list_item__group__category__in__project"
    | "list_item__category__in__group__category"
    | "list_item__asset__in__category"
    | "list_item__group__asset__in__category"
    | "list_item__asset__in__group__asset"
    | "list_item__task__in__asset"
    | "file_entry"
    | "comment"
    | "drawover"
    | "attachment__file_entry"
  );
  project: (
    "profile"
    | "ticket__invite"
    | "workspace"
    | "category"
    | "asset"
    | "task"
    | "phase"
    | "role__project__admin"
    | "role__project__editor"
    | "group__category"
    | "group__asset"
    | "role__task__assignee"
    | "list_item__category__in__project"
    | "list_item__group__category__in__project"
    | "list_item__category__in__group__category"
    | "list_item__asset__in__category"
    | "list_item__group__asset__in__category"
    | "list_item__asset__in__group__asset"
    | "list_item__task__in__asset"
    | "file_entry"
    | "comment"
    | "drawover"
    | "attachment__file_entry"
  );
  category: "workspace" | "project" | "asset" | "group__category" | "group__asset" | "list_item__category__in__project" | "list_item__group__category__in__project" | "list_item__asset__in__category" | "list_item__group__asset__in__category" | "file_entry" | "comment";
  asset: "workspace" | "project" | "category" | "task" | "group__asset" | "list_item__asset__in__category" | "list_item__asset__in__group__asset" | "list_item__task__in__asset" | "file_entry" | "comment";
  task: "workspace" | "project" | "asset" | "phase" | "role__task__assignee" | "list_item__task__in__asset" | "file_entry" | "comment";
  phase: "workspace" | "project" | "task" | "profile",

  group__category: "workspace" | "project" | "category" | "list_item__group__category__in__project" | "list_item__category__in__group__category",
  group__asset: "workspace" | "project" | "category" | "asset" | "list_item__group__asset__in__category" | "list_item__asset__in__group__asset",

  role__workspace__admin: "workspace" | "profile" | "project",
  role__project__admin: "project" | "profile" | "workspace",
  role__project__editor: "project" | "profile" | "workspace",
  role__task__assignee: "task" | "profile" | "workspace" | "project",

  list_item__category__in__project: "category" | "project" | "workspace",
  list_item__group__category__in__project: "group__category" | "project" | "workspace",
  list_item__category__in__group__category: "category" | "group__category" | "workspace" | "project",
  list_item__asset__in__category: "asset" | "category" | "workspace" | "project",
  list_item__group__asset__in__category: "group__asset" | "category" | "workspace" | "project",
  list_item__asset__in__group__asset: "asset" | "group__asset" | "workspace" | "project",
  list_item__task__in__asset: "task" | "asset" | "workspace" | "project",

  file_entry: "workspace" | "project" | "category" | "asset" | "task" | "comment" | "drawover" | "attachment__file_entry" | "ticket__discord_upload_attachment";
  comment: "workspace" | "project" | "category" | "asset" | "task" | "file_entry" | "attachment__file_entry";
  drawover: "workspace" | "project" | "file_entry";

  attachment__file_entry: "workspace" | "project" | "comment" | "file_entry";

  ticket__discord_upload_attachment: "file_entry",

}


// Rule: A child cannot exist without its parent. That means when the parent is deleted, so is the child.

export const ChildLinks: { [K in keyof Namespaces]: ChildLinkTypes[K] extends never ? undefined : ChildLinkTypes[K][] } = {

  profile: ["role__workspace__admin", "role__project__admin", "role__project__editor", "role__task__assignee"],
  ticket__invite: undefined,

  workspace: ["ticket__invite", "project", "role__workspace__admin"],
  project: ["category", "phase", "role__project__admin", "role__project__editor", "group__category", "list_item__category__in__project", "list_item__group__category__in__project"],
  category: ["asset", "group__asset", "list_item__category__in__project", "list_item__asset__in__category", "list_item__group__asset__in__category", "file_entry", "comment"],
  asset: ["task", "list_item__asset__in__category", "list_item__task__in__asset", "file_entry", "comment"],
  task: ["role__task__assignee", "list_item__task__in__asset", "file_entry", "comment"],
  phase: undefined,

  group__category: ["list_item__group__category__in__project", "list_item__group__category__in__project"],
  group__asset: ["list_item__group__asset__in__category", "list_item__asset__in__group__asset"],

  role__workspace__admin: undefined,
  role__project__admin: undefined,
  role__project__editor: undefined,
  role__task__assignee: undefined,

  list_item__category__in__project: undefined,
  list_item__group__category__in__project: undefined,
  list_item__category__in__group__category: undefined,
  list_item__asset__in__category: undefined,
  list_item__group__asset__in__category: undefined,
  list_item__asset__in__group__asset: undefined,
  list_item__task__in__asset: undefined,

  file_entry: ["comment", "drawover", "attachment__file_entry"],
  comment: ["attachment__file_entry"],
  drawover: undefined,

  attachment__file_entry: undefined,

  ticket__discord_upload_attachment: undefined,

}

export interface ChildLinkTypes extends Record<keyof Namespaces, keyof Namespaces> {

  profile: "role__workspace__admin" | "role__project__admin" | "role__project__editor" | "role__task__assignee";
  ticket__invite: never;

  workspace: "ticket__invite" | "project" | "role__workspace__admin";
  project: "category" | "phase" | "role__project__admin" | "role__project__editor" | "group__category" | "list_item__category__in__project" | "list_item__group__category__in__project";
  category: "asset" | "group__asset" | "list_item__category__in__project" | "list_item__asset__in__category" | "list_item__group__asset__in__category" | "file_entry" | "comment";
  asset: "task" | "list_item__asset__in__category" | "list_item__task__in__asset" | "file_entry" | "comment";
  task: "role__task__assignee" | "list_item__task__in__asset" | "file_entry" | "comment";
  phase: never;

  group__category: "list_item__group__category__in__project" | "list_item__group__category__in__project",
  group__asset: "list_item__group__asset__in__category" | "list_item__asset__in__group__asset",

  role__workspace__admin: never;
  role__project__admin: never;
  role__project__editor: never;
  role__task__assignee: never;

  list_item__category__in__project: never;
  list_item__group__category__in__project: never;
  list_item__category__in__group__category: never;
  list_item__asset__in__category: never;
  list_item__group__asset__in__category: never;
  list_item__asset__in__group__asset: never;
  list_item__task__in__asset: never;

  file_entry: "comment" | "drawover" | "attachment__file_entry";
  comment: "attachment__file_entry";
  drawover: never;

  attachment__file_entry: never;

  ticket__discord_upload_attachment: never,

}

// Payloads are children that do not get loaded by default in a query, unless specifically asked for.

export interface PayloadLinkTypes extends Record<keyof Namespaces, keyof Namespaces> {

  profile: never;
  ticket__invite: never;

  workspace: (
    "category"
    | "asset"
    | "task"
    | "phase"
    | "group__category"
    | "group__asset"
    | "role__project__admin"
    | "role__project__editor"
    | "role__task__assignee"
    | "list_item__category__in__project"
    | "list_item__group__category__in__project"
    | "list_item__category__in__group__category"
    | "list_item__asset__in__category"
    | "list_item__group__asset__in__category"
    | "list_item__asset__in__group__asset"
    | "list_item__task__in__asset"
    | "file_entry"
    | "comment"
    | "drawover"
    | "attachment__file_entry"
  );

  project: (
    "asset"
    | "task"
    | "phase"
    | "group__asset"
    | "role__task__assignee"
    | "list_item__category__in__group__category"
    | "list_item__asset__in__category"
    | "list_item__group__asset__in__category"
    | "list_item__asset__in__group__asset"
    | "list_item__task__in__asset"
    | "file_entry"
    | "comment"
    | "drawover"
    | "attachment__file_entry"
  );

  category: "file_entry" | "comment";
  asset: "file_entry" | "comment";
  task: "file_entry" | "comment";
  phase: never;

  group__category: never,
  group__asset: never,

  role__workspace__admin: never;
  role__project__admin: never;
  role__project__editor: never;
  role__task__assignee: never;

  list_item__category__in__project: never;
  list_item__group__category__in__project: never;
  list_item__category__in__group__category: never;
  list_item__asset__in__category: never;
  list_item__group__asset__in__category: never;
  list_item__asset__in__group__asset: never;
  list_item__task__in__asset: never;

  file_entry: "comment" | "drawover";
  comment: never;
  drawover: never;

  attachment__file_entry: never;

  ticket__discord_upload_attachment: never,

}

export const PayloadLinks: { [K in keyof Namespaces]: PayloadLinkTypes[K] extends never ? undefined : PayloadLinkTypes[K][] } = {

  profile: undefined,
  ticket__invite: undefined,


  workspace: [
    "category",
    "asset",
    "task",
    "phase",
    "group__category",
    "group__asset",
    "role__project__admin",
    "role__project__editor",
    "role__task__assignee",
    "list_item__category__in__project",
    "list_item__group__category__in__project",
    "list_item__category__in__group__category",
    "list_item__asset__in__category",
    "list_item__group__asset__in__category",
    "list_item__asset__in__group__asset",
    "list_item__task__in__asset",
    "file_entry",
    "comment",
    "drawover",
    "attachment__file_entry",
  ],

  project: [
    "asset",
    "task",
    "phase",
    "group__asset",
    "role__task__assignee",
    "list_item__category__in__group__category",
    "list_item__asset__in__category",
    "list_item__group__asset__in__category",
    "list_item__asset__in__group__asset",
    "list_item__task__in__asset",
    "file_entry",
    "comment",
    "drawover",
    "attachment__file_entry",
  ],

  category: ["file_entry", "comment"],
  asset: ["file_entry", "comment"],
  task: ["file_entry", "comment"],
  phase: undefined,

  group__category: undefined,
  group__asset: undefined,

  role__workspace__admin: undefined,
  role__project__admin: undefined,
  role__project__editor: undefined,
  role__task__assignee: undefined,

  list_item__category__in__project: undefined,
  list_item__group__category__in__project: undefined,
  list_item__category__in__group__category: undefined,
  list_item__asset__in__category: undefined,
  list_item__group__asset__in__category: undefined,
  list_item__asset__in__group__asset: undefined,
  list_item__task__in__asset: undefined,

  file_entry: ["comment", "drawover"],
  comment: undefined,
  drawover: undefined,

  attachment__file_entry: undefined,

  ticket__discord_upload_attachment: undefined,

}

// General utilities.

export function isNamespaceKey(key: string): key is keyof Namespaces {
  return key in schema;
}


// Attachment utilities

type AttachmentType = {
  [K in keyof Namespaces]: K extends `attachment__${string}`? K : never;
}[keyof Namespaces];

export function isAttachmentType(key: keyof Namespaces): key is AttachmentType {
  return key.startsWith("attachment__");
}

// Parent utilities.

type ParentLinkType<T extends keyof Namespaces> = {
  [K in keyof Namespaces]: T extends ChildLinkTypes[K] ? K : undefined;
}[keyof Namespaces];

export type ParentLinkTypes = {
  [K in keyof Namespaces]: ParentLinkType<K>;
};

type AncestorLinkType<T extends keyof Namespaces> = T extends keyof ParentLinkTypes
  ? ParentLinkTypes[T] extends keyof ParentLinkTypes
    ? ParentLinkTypes[T] | AncestorLinkType<ParentLinkTypes[T]>
    : ParentLinkTypes[T]
  : never;

export type AncestorLinkTypes = {
  [K in keyof Namespaces]: AncestorLinkType<K>;
};

function createParentLinks(): { [K in keyof Namespaces]: ParentLinkTypes[K] extends never ? undefined : ParentLinkTypes[K][] } {
  const parentLinks = {} as { [K in keyof Namespaces]: ParentLinkTypes[K] extends never ? undefined : ParentLinkTypes[K][] };

  for (const parentKey in schema) {

    const children = ChildLinks[parentKey as keyof Namespaces];

    for (const child of (children || [])) {

      if (!parentLinks[child]) {
        parentLinks[child] = [];
      }

      // Comments and file entries are special cases. They have multiple parents.
      if (child === "comment") {
        parentLinks[child].push(parentKey as ParentLinkType<typeof child>);
        continue;
      }
      if (child === "file_entry") {
        parentLinks[child].push(parentKey as ParentLinkType<typeof child>);
        continue;
      }

      // Attachments are also a special case. They have two parents, but only one parent, the comment.
      if (isAttachmentType(child)) {
        parentLinks[child] = ["comment"];
        continue;
      }

      // List items are special case. They have two parents, but only one ancestor parent (the "from" key).
      if (isListItemLinkKey(child)) {
        //@ts-ignore
        parentLinks[child] = [getListItemFromKey(child)];
        continue;
      }

      // Roles are also a special case. Technically the profile is also a parent, but the ancestor is really the target key.
      if (isRoleLinkKey(child)) {
        //@ts-ignore
        parentLinks[child] = [getRoleTargetKey(child)];
        continue;
      }

      // All other items have only one parent.
      if (parentLinks[child] && parentLinks[child].length > 0) {
        throw new Error(`Multiple parent types found for namespace '${child}'`);
      }

      //@ts-ignore
      parentLinks[child] = [parentKey as keyof Namespaces];
    }
  }

  // @ts-ignore
  return parentLinks as ParentLinkTypes;
}

export const ParentLinks: { [K in keyof Namespaces]: ParentLinkTypes[K] extends never ? undefined : ParentLinkTypes[K][] } = createParentLinks();



// List Item type utilities

export type ListItemLinkTypes = {
  [K in keyof typeof Links as K extends `list_item__${string}` ? K : never]: typeof Links[K][number];
};


export type ListItemKey<To extends keyof Namespaces, From extends keyof Namespaces> =  `list_item__${To}__in__${From}` extends keyof ListItemLinkTypes
  ? `list_item__${To}__in__${From}`
  : never;


type ListItemLinkParts<K extends string> = K extends `list_item__${infer To extends keyof Namespaces}__in__${infer From extends keyof Namespaces}`
  ? { to: To; from: From }
  : never;


export type ListItemLinkTo<K extends keyof ListItemLinkTypes> = ListItemLinkParts<K>['to'];
export type ListItemLinkFrom<K extends keyof ListItemLinkTypes> = ListItemLinkParts<K>['from'];


export function getFullListItemKey<K extends keyof Namespaces, L extends keyof Namespaces>(from: K, to: L): ListItemKey<L, K> {
  return `list_item__${to}__in__${from}` as ListItemKey<L, K>;
}


export function isListItemLinkKey(key: string): key is keyof ListItemLinkTypes {
  return key in Links && key.startsWith("list_item__");
}

export function getListItemToKey<K extends keyof ListItemLinkTypes>(key: K): ListItemLinkTo<K> {
  return Links[key][0] as ListItemLinkTo<K>;
}

export function getListItemFromKey<K extends keyof ListItemLinkTypes>(key: K): ListItemLinkFrom<K> {
  return Links[key][1] as ListItemLinkFrom<K>;
}


// Role type utilities

export type RoleLinkTypes = {
  [K in keyof typeof Links as K extends `role__${string}` ? K : never]: typeof Links[K][number];
};

export type RoleKey<K extends keyof Namespaces, Name extends string> =  `role__${K}__${Name}` extends keyof RoleLinkTypes
  ? `role__${K}__${Name}`
  : never;

export type RoleName<K extends keyof RoleLinkTypes> = K extends `role__${string}__${infer Name}` ? Name : never;

export function isRoleLinkKey(key: string): key is keyof RoleLinkTypes {
  return key in Links && key.startsWith("role__");
}

export function getRoleTargetKey<K extends keyof RoleLinkTypes>(key: K): keyof Namespaces {
  return key.split("__")[1] as keyof Namespaces;
}

export function getRoleNameFromKey<K extends keyof RoleLinkTypes>(key: K): string {
  return key.split("__")[2];
}
