type BaseItem = {
    id: string;
};


type InsertOperation<Item extends BaseItem> = {
    startItem: Item | null;
    endItem: Item | null;
    items: Item[];
};


function longestCommonSubsequence<Item extends BaseItem>(items: Item[], reorderedItems: Item[]): string[] {
    const idToItem = new Map(items.map(item => [item.id, item]));
    const validNewOrder = reorderedItems.map(item => item.id);
    const originalOrderIndex = new Map(items.map((item, index) => [item.id, index]));

    let maxLen = 0;
    let bestStart = -1;

    // This will store the length of the longest subsequence ending at each index of the new order
    let dp = new Array(validNewOrder.length).fill(0);
    let previous = new Array(validNewOrder.length).fill(-1);

    for (let i = 0; i < validNewOrder.length; i++) {
        let currentId = validNewOrder[i];
        dp[i] = 1;  // Every single item is a subsequence of length 1

        for (let j = 0; j < i; j++) {
            let previousId = validNewOrder[j];
            if (originalOrderIndex.get(previousId)! < originalOrderIndex.get(currentId)! && dp[j] + 1 > dp[i]) {
                dp[i] = dp[j] + 1;
                previous[i] = j;
            }
        }

        if (dp[i] > maxLen) {
            maxLen = dp[i];
            bestStart = i;
        }
    }

    // Reconstruct the longest subsequence
    let subsequence = [] as Item[];
    while (bestStart != -1) {
        subsequence.unshift(idToItem.get(validNewOrder[bestStart])!);
        bestStart = previous[bestStart];
    }

    return (subsequence.filter(x => !!x) as Item[]).map(x => x.id);

}

export function removeDuplicates<T>(arr: T[]): T[] {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}


export function reorderItems<Item extends BaseItem>(items: Item[], newOrder: string[]): Item[] {

    const idToItem = new Map(items.map(item => [item.id, item]));
    const validNewOrder = removeDuplicates(newOrder).filter(id => idToItem.has(id));
    const reorderedItems = validNewOrder.map(id => idToItem.get(id)!);

    // Items not included in newOrder are appended at the end
    const itemIdsInNewOrder = new Set(validNewOrder);
    const remainingItems = items.filter(item => !itemIdsInNewOrder.has(item.id));
    reorderedItems.push(...remainingItems);

    return reorderedItems;
}


export function getInsertOperationsOld<Item extends BaseItem>(order: Item[], newOrder: Item[]) {


  const commonSubsequence = longestCommonSubsequence(order, newOrder);
  const insertOperations: InsertOperation<Item>[] = [];

  for (let i = -1; i < commonSubsequence.length; i++) {
    let start = -1;
    let end = -1;
    if (i == -1) {
      start = 0;
      end = newOrder.findIndex(x => x.id == commonSubsequence[i + 1]);
    } else if (i == commonSubsequence.length - 1) {
      start = newOrder.findIndex(x => x.id == commonSubsequence[i]) + 1;
      end = newOrder.length;
    }
    else {
      start = newOrder.findIndex(x => x.id == commonSubsequence[i]) + 1;
      end = newOrder.findIndex(x => x.id == commonSubsequence[i + 1]);
    }
    const inbetweenItems = newOrder.slice(start, end);
    if (inbetweenItems.length > 0) {
      insertOperations.push({
        startItem: newOrder.find(x => x.id === commonSubsequence[i]) || null,
        endItem: newOrder.find(x => x.id === commonSubsequence[i + 1]) || null,
        items: inbetweenItems,
      })
    }
  }

  return insertOperations;

}

export function getInsertOperations<Item extends BaseItem>(order: Item[], newOrder: Item[]) {

  function _getInsertOperations(order: Item[], newOrder: Item[]) {
    const commonSubsequence = longestCommonSubsequence(order, newOrder);
    const insertOperations: InsertOperation<Item>[] = [];

    let start = 0; // Initialize start at the beginning of newOrder

    commonSubsequence.forEach((id, index) => {
      // Find the index of the current common item in the newOrder
      const currentIndex = newOrder.findIndex(x => x.id === id);

      // Slice out items from start to currentIndex to find in-between items before this common item
      const inbetweenItems = newOrder.slice(start, currentIndex);
      if (inbetweenItems.length > 0) {
        // Insert from the last common item or null if at the start
        insertOperations.push({
          startItem: index > 0 ? order.find(x => x.id === commonSubsequence[index - 1]) || null : null,
          endItem: order.find(x => x.id === id) || null,
          items: inbetweenItems,
        });
      }

      // Update start to the next index after the current common item
      start = currentIndex + 1;
    });

    // Handle trailing items in the newOrder after the last item of the common subsequence
    const trailingItems = newOrder.slice(start);
    if (trailingItems.length > 0) {
      insertOperations.push({
        startItem: commonSubsequence.length > 0 ? order.find(x => x.id === commonSubsequence[commonSubsequence.length - 1]) || null : null,
        endItem: null,
        items: trailingItems,
      });
    }

    return insertOperations;
  }

  /*
  const newItems = newOrder.filter(x => !order.find(y => y.id === x.id));
  const extendedItems = [...order, ...newItems];
  const operations = _getInsertOperations(extendedItems, newOrder);

  // Post process operations. Make sure newItems are explicitly included.
  // Iterate through each new item. If it exists in the operations, no action necessary.
  // For all items that do not exist in an operation, prepend an operation which places them at the end of the list.
  const insertAtEnd: Item[] = [];
  newItems.forEach(newItem => {
    operations.forEach(operation => {
      if (operation.items.find(x => x.id === newItem.id)) return;
    });
    insertAtEnd.push(newItem);
  })

  if (insertAtEnd.length) {
    return [
      {
        startItem: order[order.length - 1] || null,
        endItem: null,
        items: insertAtEnd,
      },
      ...operations,
    ];
  }

  else {
    return operations;
  }
  */

  return _getInsertOperations(order, newOrder);
}